<template>
    <div>
      <div>
        <div class="container-fluid p-0">
          <div class="row no-gutters">
            <div class="col-lg-4">
              <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div class="w-100">
                  <div class="row justify-content-center">
                    <div class="col-lg-9">
                      <div>
                        <div class="text-center">
                          <div>
                            <a href="/" class="logo">
                              <img src="@/assets/images/logo.png" height="80" alt="logo" />
                            </a>
                          </div>
  
                          <h4 class="font-size-18 mt-4">Hosgeldiniz !</h4>
                          <p class="text-muted">Kayıt olmak için lütfen bilgileri yazınız</p>
                        </div>
  
                        <div class="p-2 mt-5">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-user-2-line auti-custom-input-icon"></i>
                            <label for="username">E-mail</label>
                            <input type="text" class="form-control" id="email" v-model="email"
                              placeholder="E-mail Adresiniz"/>
                          </div>
  
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Şifre</label>
                            <input class="form-control" type="password" v-model="password" trim
                              placeholder="Şifre"/>
                            
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-map-pin-user-line auti-custom-input-icon"></i>
                            <label for="userpassword">Adınız</label>
                            <input class="form-control" type="text" v-model="adi" trim
                              placeholder="Adınız"/>
                            
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-map-pin-user-fill auti-custom-input-icon"></i>
                            <label for="userpassword">Soyadınız</label>
                            <input class="form-control" type="text" v-model="soyadi" trim
                              placeholder="Soyadınız"/>
                            
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-phone-line auti-custom-input-icon"></i>
                            <label for="userpassword">Telefon Numaranız</label>
                            <input class="form-control" type="text" v-model="telefon" trim
                              placeholder="Telefon Numaranız"/>
                            
                          </div>
  
  
  
                          <div class="mt-4 text-center">
                            <button class="btn btn-primary w-md waves-effect waves-light" @click="LisansOlustur"
                              v-on:keyup.enter="LisansOlustur">Kayıt Ol</button>
                          </div>
                        </div>
  
                        <div class="mt-5 text-center">
                          <p>
                            © 2021 OtoPrinter
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="authentication-bg"
                :style="{'background-image': 'url(' + require('../../../assets/images/background.jpg') + ')'}">
                <div class="bg-overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { axiosInstance } from '../../../helpers/axios';
    export default {
      name: "kullanici-olustur",
      data() {
        return {
            email:null,
            password:null,
            adi:null,
            soyadi:null,
            telefon:null,
        }
      },
      methods: {
        LisansOlustur()
        {
        var vm = this;
            try {

            axiosInstance.post("users/create",{
                email:vm.email,
                password:vm.password,
                role:"admin",
                adi:vm.adi,
                soyadi:vm.soyadi,
                telefon:vm.telefon,
                hakkinda:"",
            }).then((response) => {

            if(response.data.error==true)
                    {
                    vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                        title: `Uyari !`,
                        variant: "warning",
                        solid: true
                    });
                    }else{
                    vm.$bvToast.toast("Kullanıcı Oluşturulmuştur", {
                        title: `Bildirim !`,
                        variant: "info",
                        solid: true
                    });

                    setInterval(() => {
                        

                        this.$router.push('giris')
                        
                    }, 3000);
             }

            }).catch((error)=>{
                vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                    title: `Uyari !`,
                    variant: "warning",
                    solid: true
                    });
            })
            
            } catch (error) {
                vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                    title: `Uyari !`,
                    variant: "warning",
                    solid: true
                    });
            }
        }
      }
    }
  </script>